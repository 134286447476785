import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import LaunchIcon from '@mui/icons-material/Launch';
import { Pdf, Link } from '.'
import * as DOMPurify from 'dompurify';

import { ShowMoreText } from '@nnsa/helpers';


const getURL = () => {
    // return document.body.dataset.portalUrl
}

const useStyles = makeStyles({

    root: {
        boxShadow: "none !important",
        '& .MuiTableCell-head': {
            background: '#eceff1 !important',
        },
        '& tbody tr:nth-child(even)': {
            background: "#eee"
        }
    },

    tableContainer: {

    },

    tableHead: {
        cursor: "pointer",
    },

    tableSortLabel: {
        // '&:hover, &.Mui-focusVisible': {
        //     outline: 'auto',
        // },
        // '&.Mui-active': {
        //     outline: 'auto',
        // },

    },

    tableDefaultSorting: {
        content: "''",
        display: "inline-flex",
        backgroundImage: 'url("./updown.svg")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        width: "1em",
        height: "1em",
        opacity: "0.5",
        padding: "3px",
        transform: "translateX(3px)"
    },

    tableHeaderLabel: {
        padding: "0px",
        margin: "0px",
        whiteSpace: "nowrap",
    },

    emptyCell: {
        background: "rgb(0 0 0 / 2%)"
    },

    stickyHeader: {
        position: "sticky",
        top: 0,
        zIndex: 5,
        overflowX: "auto",
        background: '#eceff1',
        '& p': {
            margin: "0px"
        }

    },

});


export default function TableView({ headers, data, linkon, sort, setSort }) {


    const theme = useTheme()
    const classes = useStyles();

    const tableHeaderRef = React.useRef()
    const stickyHeaderRef = React.useRef()
    const [tableContainerRect, setTableContainerRect] = React.useState(0)

    const tableContainerRef = React.useCallback(node => {
        if (node !== null) {
            setTableContainerRect(node.getBoundingClientRect());
        }
    }, []);

    const getDynamicTableContainerHeight = () => {
        return tableContainerRect?.top ? window.pageYOffset + tableContainerRect.top : 0
    }

    const handleScrollCopied = (scroll) => {
        // tableContainerRef.current.scrollLeft = scroll.target.scrollLeft;
    };

    const handleScroll = (scroll) => {
        // stickyHeaderRef.current.scrollLeft = scroll.target.scrollLeft
    };

    const handleSorting = (field) => {
        let direction = "desc"
        if (sort[1] !== 'undefined' && sort[0] !== 'undefined') {
            if (sort[1] === "desc") direction = "asc"
        }

        setSort(field, direction)
    }

    const formatDate = date => {
        date = new Date(date)
        return (
            (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear()
        );
    }

    const putColumn = (rowName, value, row) => {

        let column = ""

        // TODO: Use common/doesStringContainHighlight before decoding html
        let valueHTML = <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />;


        switch (rowName.toLowerCase()) {
            case linkon:
                column = (
                    <Link
                        url={row.url}
                        value={valueHTML}
                    />
                )
                break;

            case "description":
                column = <ShowMoreText text={value} />
                break;

            case "docid":
                column = (
                    <Pdf
                        docURL={row.url}
                        Id={valueHTML}
                    />
                )
                break;
            default:
                column = <div>{valueHTML}</div>
        }


        return column
    }

    const putTable = () => {
        return (
            <div>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer
                        id="searchapp-tableResultView-tableContainer"
                        className={classes.tableContainer}
                        ref={tableContainerRef}
                        onScroll={handleScroll}
                        sx={{ maxHeight: `calc(100vh - ${parseInt(getDynamicTableContainerHeight())}px)` }}
                    >
                        <Table className={classes.root} stickyHeader>
                            <TableHead>
                                <TableRow ref={tableHeaderRef}>
                                    {
                                        headers.map((header) => {
                                            const sortingField = header['sort_on'] ? header['sort_on'] : header['name'];
                                            const sortIsActive = sort[0] && sort[0].toLowerCase() === sortingField.toLowerCase() ? true : false;

                                            return (
                                                <TableCell
                                                    onClick={() => handleSorting(sortingField)}
                                                    key={sortingField}
                                                    id={sortingField}
                                                    className={classes.tableHead}
                                                >
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <p className={classes.tableHeaderLabel}>
                                                            {header['alias'] ? header['alias'].toUpperCase() : header['name'].toUpperCase()}
                                                        </p>

                                                        <TableSortLabel
                                                            active={sortIsActive}
                                                            direction={sort[1]}
                                                            className={sortIsActive ? classes.tableSortLabel : classes.tableDefaultSorting}
                                                            hideSortIcon={true}
                                                        />
                                                    </div>
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {data
                                    .map((row, i) => {
                                        return (
                                            <>
                                                <TableRow role="checkbox" key={row.UID}>
                                                    {headers.map((header, k) => {
                                                        let value = row[header['name']];

                                                        // check if column is a date
                                                        var re = new RegExp(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/gm);
                                                        if (re.test(value)) {
                                                            value = formatDate(value)
                                                        }

                                                        if (value === null) {
                                                            return (
                                                                <TableCell key={"empty" + k} className={classes.emptyCell}>
                                                                </TableCell>
                                                            )

                                                        }
                                                        else {
                                                            return (
                                                                <TableCell key={headers[k]['name'] + "_" + value}
                                                                    className={classes.tableCell}
                                                                >
                                                                    {
                                                                        putColumn(headers[k]['name'], value, row)
                                                                    }
                                                                </TableCell>
                                                            );
                                                        }

                                                    })}
                                                </TableRow>
                                                {/* <tr role="solr-score-testing">
                                                    <td colspan="2">
                                                        <span
                                                            style={{
                                                                background: "#F1D302",
                                                                padding: "5px",
                                                                position: "relative",
                                                                top: "-25px",
                                                                left: "1px",
                                                                borderRadius: "5px"
                                                            }}
                                                        >Score {row['score']}
                                                        </span>
                                                    </td>
                                                </tr> */}
                                            </>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
        );
    }

    return (
        <>
            {
                (headers && data) ? (putTable()) : ""
            }
        </>
    )
}